function Logo(props) {
  return (
    <svg
      viewBox='0 0 399 399'
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit={2}
      {...props}
    >
      <path fill='none' d='M0 0h398.519v398.519H0z' />
      <path
        d='M313.973 218.967v-46.068c0-11.041 6.897-16.662 14.224-16.662 7.255 0 14.438 5.492 14.438 16.676v58.586c0 11.413-1.032 16.905-8.23 29.996-11.37 20.661-32.118 54.586-32.118 95.493h-71.734s-8.015-25.221-10.94-42.183c-5.893-34.083 1.806-49.769 15.901-64.308 14.654-15.098 21.909-22.238 28.619-29.178 15.5-16.073 36.591 2.782 24.289 17.521-6.71 8.044-23.285 25.852-27.257 31.129-3.843 5.104 3.312 12.288 8.947 6.409 8.216-8.589 27.315-29.867 33.91-37.538 6.352-7.413 9.951-11.886 9.951-19.873zm-229.441 0v-46.068c0-11.041-6.854-16.662-14.181-16.662-7.241 0-14.467 5.492-14.467 16.676v58.586c0 11.413 1.032 16.905 8.23 29.996 11.37 20.661 32.146 49.625 32.146 95.493h71.735s8.015-25.221 10.94-42.183c5.893-34.083-1.807-49.769-15.901-64.308-14.654-15.098-21.909-22.238-28.619-29.178-15.5-16.073-36.592 2.782-24.289 17.521 6.71 8.044 23.285 25.852 27.257 31.129 3.842 5.104-3.313 12.288-8.947 6.409-8.216-8.589-27.315-29.867-33.91-37.538-6.352-7.413-9.994-11.886-9.994-19.873zm114.72-177.436c-34.928 51.604-57.353 83.879-57.353 115.237 0 31.401 25.651 56.823 57.353 56.823s57.353-25.422 57.353-56.823c0-31.358-22.425-63.633-57.353-115.237zm-.2 36.706c3.369 5.191 7.369 14.252 7.369 23.802 0 28.017-28.676 32.605-28.676 15.557 0-11.715 14.252-28.992 21.307-39.359z'
        fill='none'
        className="stroke-current text-gray-900 dark:text-gray-50"
        strokeWidth={9.5}
      />
    </svg>
  );
}

export default Logo;
